import styled from "styled-components";
import { device } from "../device";

export const ErrorLine = styled.div`
    background-color: ${(props) => props.theme.colors.error};
    margin-right: 16px;
    min-width: 4px;
    @media ${device.tablet} {
      margin-right: 28px;
    }
`;
export const SVGWrapper = styled.div`
    margin-top: 32px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: auto;

    @media ${device.tablet} {
      height: 500px;
    }

    svg {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
`;
export const ButtonWrapper = styled.section`
    margin-top: 32px;
    padding-bottom: 128px;
    width: 100%;
    display: flex;

    a {
        margin: auto;
    }
`;