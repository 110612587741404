import { PureComponent } from 'react';
import StyledLink from '../../../shared/components/global/StyledLink';
import { Button, ButtonSizes } from '../../../shared/styles/button';
import { Container, ContainerContentStandard } from '../../../shared/styles/container';
import { ButtonWrapper, ErrorLine, SVGWrapper } from '../../../shared/styles/errors/errors';
import Layout from '../components/global/Layout';
import Seo from '../components/global/Seo';
import { ContactGrid, Subheadline, TextOnlyPageContainer } from './impressum';
import ErrorSVG from '/public/svg/errors/404.svg';

export default class Custom404 extends PureComponent {
    render() {
        const title = 'Error 404';
        const description = 'Die Seite ist leider nicht verfügbar.';

        return (
            <>
                <Seo title={title} description={description} />
                <Layout preview={false}>
                    <TextOnlyPageContainer>
                        <Container>
                            <ContainerContentStandard>
                                <ContactGrid>
                                    <div>
                                        <h1>Sorry!</h1>
                                        <Subheadline>
                                            <ErrorLine />
                                            <h2>
                                                Die gewünschte Seite konnte nicht gefunden werden.
                                            </h2>
                                        </Subheadline>
                                    </div>
                                    <SVGWrapper>
                                        <ErrorSVG />
                                    </SVGWrapper>
                                    <ButtonWrapper>
                                        <StyledLink href="/">
                                            <Button primary size={ButtonSizes.m}>
                                                {"Zur Startseite"}
                                            </Button>
                                        </StyledLink>
                                    </ButtonWrapper>
                                </ContactGrid>
                            </ContainerContentStandard>
                        </Container>
                    </TextOnlyPageContainer>
                </Layout>
            </>
        );
    }
}
