import { PureComponent } from "react";
import styled from "styled-components";
import {
  Container,
  ContainerContentStandard,
} from "../../../shared/styles/container";
import { device } from "../../../shared/styles/device";
import Layout from "../components/global/Layout";
import Seo from "../components/global/Seo";

export const TextOnlyPageContainer = styled.div`
  min-height: 100vh;
  overflow: hidden;
  width: 100%;

  h1 {
    font-size: 48px;
    font-weight: 800;
    line-height: 78px;
    margin-top: 0;
  }
  h2,
  h3 {
    font-size: 20px;
    line-height: 43px;
    margin: 0;
    word-break: break-word;
  }

  @media ${device.tablet} {
    h1 {
      font-size: 68px;
    }
    h2,
    h3 {
      font-size: 26px;
    }
  }

  section {
    margin-top: 64px;
  }
`;

export const Subheadline = styled.div`
  display: flex;
  flex-direction: row;
`;
export const WhiteLine = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  margin-right: 16px;
  min-width: 4px;
  @media ${device.tablet} {
    margin-right: 28px;
  }
`;
export const ContactGrid = styled.div`
  display: flex;
  flex-direction: column;
`;
export default class Imprint extends PureComponent {
  render() {
    const title = "Impressum";
    const description = "Impressum";

    return (
      <>
        <Seo title={title} description={description} />
        <Layout preview={false}>
          <TextOnlyPageContainer>
            <Container>
              <ContainerContentStandard>
                <ContactGrid>
                  <div>
                    <h1>Impressum</h1>
                    <Subheadline>
                      <WhiteLine />
                      <h2>
                        easyboarding ist ein Projekt von TRVL Counter und
                        STELL-MICH-EIN, in Kooperation mit dem Deutschen
                        Reiseverband (DRV).
                      </h2>
                    </Subheadline>
                  </div>
                  <section>
                    <p>
                      <b>easyboarding UG (haftungsbeschränkt)</b>
                    </p>
                    <p>Langbehnstraße 10</p>
                    <p>22761 Hamburg</p>
                    <p>Telefon: + 49 (0) 40 389040 0</p>
                    <p>E-Mail: info@easyboarding.de</p>
                    <p>Geschäftsführer: Steven Hille</p>
                    <p>Registergericht: Amtsgericht Hamburg</p>
                    <p>Registernummer: HRB 1777 49</p>
                  </section>
                  <section>
                    <p>
                      Umsatzsteuer-Identifikationsnummer: DE356662163 (gemäß §
                      27 a Umsatzsteuergesetz)
                    </p>
                    <p>
                      Inhaltlich Verantwortliche: siehe Geschäftsführer (gemäß §
                      6 MDStV)
                    </p>
                  </section>
                  <section>
                    <p>
                      Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle
                      übernehmen wir keine Haftung für die Inhalte externer
                      Links. Für den Inhalt der verlinkten Seiten sind
                      ausschließlich deren Betreiber verantwortlich.
                    </p>
                  </section>
                  <section>
                    <b>
                      Bildnachweis: Die Bilder stammen aus folgenden Quellen:
                    </b>
                    <ul>
                      <li>Unsplash - https://unsplash.com/</li>
                      <li>Pexels - https://www.pexels.com/</li>
                      <li>iStockphotos</li>
                      <li>easyboarding</li>
                      <li>TRVL Counter</li>
                    </ul>
                  </section>
                  <section>
                    <b>Software Entwicklung: Christoph Meise</b>
                    <p>https://explorechristoph.com/</p>
                  </section>
                </ContactGrid>
              </ContainerContentStandard>
            </Container>
          </TextOnlyPageContainer>
        </Layout>
      </>
    );
  }
}
